import firebase from "firebase/compat/app";

import Store, { Comment, Report } from "..";
import Campaign from "./campaign";
import Fund from "./fund";
import { Profile, User } from "../auth";

class Admin {
  readonly store;

  constructor(store: Store) {
    this.store = store;
    // firebase.app().functions("europe-west1").useEmulator("localhost", 5001) // Used to test on local functions emulator
  }

  user = (uid: string) => {
    return new User(
      firebase.firestore().collection("user").doc(uid),
      {},
      this.store,
    );
  };

  profile = (uid: string) => {
    return new Profile(
      firebase.firestore().collection("profile").doc(uid),
      {},
      this.store,
    );
  };

  profiles = async () => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection("profile")
        .orderBy("createdAt", "desc")
        .get();
      return snapshot.docs.map((doc) => new Profile(doc.ref, {}, this.store));
    } catch (error) {
      console.error("Error loading profiles: ", error);
    }
  };

  campaigns = async () => {
    try {
      const snapshot = await firebase
        .firestore()
        .collectionGroup("campaign")
        .orderBy("createdAt", "desc")
        .get();
      return snapshot.docs.map((doc) => new Campaign(doc.ref, {}, this.store));
    } catch (error) {
      console.error("Error loading campaigns: ", error);
    }
  };

  comments = async () => {
    try {
      const snapshot = await firebase
        .firestore()
        .collectionGroup("comment")
        .orderBy("createdAt", "desc")
        .get();
      return snapshot.docs.map((doc) => new Comment(doc.ref, {}, this.store));
    } catch (error) {
      console.error("Error loading comments: ", error);
    }
  };

  reports = async () => {
    try {
      const snapshot = await firebase
        .firestore()
        .collectionGroup("report")
        .orderBy("createdAt", "desc")
        .get();
      return snapshot.docs.map((doc) => new Report(doc.ref, {}, this.store));
    } catch (error) {
      console.error("Error loading reports: ", error);
    }
  };

  funds = async (campaign: Campaign) => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection(`${campaign.path}/fund`)
        .where("status", "==", "paid")
        .orderBy("createdAt", "desc")
        .get();
      return snapshot.docs.map((doc) => new Fund(doc.ref, {}, this.store));
    } catch (error) {
      console.error("Error loading reports: ", error);
    }
  };

  allFunds = async () => {
    const snapshot = await firebase
      .firestore()
      .collectionGroup("fund")
      .orderBy("createdAt", "desc")
      .get();
    return snapshot.docs.map((doc) => new Fund(doc.ref, {}, this.store));
  };

  async createRefund(fund: Fund) {
    const fundId = fund.id;
    const campaignId = fund.campaign?.id;
    const clubId = fund.campaign?.club?.id;
    const leagueId = fund.campaign?.club?.league?.id;
    const countryId = fund.campaign?.club?.league?.country?.id;

    await firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("paymentRefund")({
      countryId,
      leagueId,
      clubId,
      campaignId,
      fundId,
    });
  }

  async refundCampaign(campaign: Campaign) {
    const campaignId = campaign?.id;
    const clubId = campaign?.club?.id;
    const leagueId = campaign?.club?.league?.id;
    const countryId = campaign?.club?.league?.country?.id;

    await firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("refundCampaign")({
      countryId,
      leagueId,
      clubId,
      campaignId,
    });
  }
}

export default Admin;
