import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Receipt from "./receipt";
import { useStore, Fund } from "../../store";
import Share from "../campaign/share";
import Preloader from "../general/preloader";

interface Props {
  fund: Fund;
}
const Complete = (props: Props) => {
  const { fund } = props;
  const { isCompleted, isFailed, isCanceled, isVerified, isExpired } = fund;
  const [showShare, setShowShare] = useState(false);
  const { auth } = useStore();
  const { t } = useTranslation();

  const renderLink = () => {
    if (auth.isSignedIn) return;
    return (
      <div className="fund-completed">
        <p>
          {t(
            "Do you want to stay informed about this support action and have an overview of your donations? Register now.",
          )}
        </p>
        <Link to={`/signin`} className="btn btn-primary">
          {t("Register now")}
        </Link>
      </div>
    );
  };

  let content;

  if (isFailed || isExpired || isVerified) {
    content = (
      <div className="fund-completed">
        <h1>{t("Sorry")}</h1>
        <p>
          {t("Something went wrong with the transaction of your donation.")}
        </p>
        <Link to={`..`} className="btn btn-primary">
          {t("Try again")}
        </Link>
      </div>
    );
  } else if (isCompleted) {
    content = (
      <div className="fund-completed">
        <h1>{t("Thank you for your donation")}</h1>
        <p>
          {t(
            "We received your donation and it has been added to the support action.",
          )}
        </p>
        {renderLink()}
        <Link to={fund.campaign?.link || "#"} className="btn btn-primary">
          {t("Back to support action")}
        </Link>
        {fund.campaign && (
          <>
            <Button
              className="ms-sm-2"
              variant="secondary"
              type="button"
              onClick={() => setShowShare(true)}
            >
              {t("Share")}
              <i className="far fa-paper-plane ms-1"></i>
            </Button>
            <Share
              show={showShare}
              onHide={() => setShowShare(false)}
              campaign={fund.campaign}
            />
          </>
        )}
      </div>
    );
  } else if (isCanceled) {
    content = (
      <div className="fund-completed">
        <h1>{t("Payment canceled")}</h1>
        <p>{t("Your account has not been charged.")} </p>
        {renderLink()}
        <Link
          to={`${fund.campaign?.club?.link}/explore`}
          className="btn btn-primary"
        >
          {t("Back to support action")}
        </Link>
      </div>
    );
  } else {
    return <Preloader inline />;
  }
  return (
    <Container>
      <Row>
        <Col lg={8} md={6} xs={12}>
          {content}
        </Col>
        <Col lg={4} md={6} xs={12}>
          {isCompleted && <Receipt fund={fund} />}
        </Col>
      </Row>
    </Container>
  );
};

export default observer(Complete);
