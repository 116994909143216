import classNames from "classnames";
import { Collection } from "firestorter";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Masonry from "react-masonry-component";
import { Link, useLocation } from "react-router-dom";

import CampaignFilter from "./filter";
import { useStore, Campaign, Club, Query, League } from "../../store";
import CampaignItem from "../campaign/item";
import Preloader from "../general/preloader";

type FilterType = "all" | "popular" | "new" | "cashed";

export interface Filter {
  type?: FilterType;
  club?: Club;
  league?: League;
}

interface Props {
  filter?: Filter;
  hide?: ("club" | "league")[];
  limit?: number;
  isEmbed?: boolean;
}
const Discover = (props: Props) => {
  const { limit, hide, isEmbed } = props;
  const { data } = useStore();
  const location = useLocation();
  const [filter, setFilter] = useState({
    ...props.filter,
    type: props.filter?.type || (location.hash?.replace("#", "") as FilterType),
  });
  const [isLoaded, setLoaded] = useState(false);
  const [campaigns, setCampaigns] = useState<Collection<Campaign>[]>();
  const { t } = useTranslation();

  useEffect(() => {
    if (filter?.type) window.location.hash = filter.type;
  }, [filter?.type]);

  const query = (): Query => {
    switch (filter?.type) {
      case "all":
        return {
          orderBy: ["createdAt", "desc"],
        };
      case "popular":
        return {
          orderBy: ["fundings", "desc"],
        };
      case "new":
        return {
          orderBy: ["createdAt", "desc"],
        };
      case "cashed":
        return {
          where: ["cashed", "==", true],
          orderBy: ["createdAt", "desc"],
        };
      default:
        return {
          orderBy: ["createdAt", "desc"],
        };
    }
  };

  // Old one for filter Campaigns

  // const filteredCampaigns = useMemo(() => {
  //   setLoaded(false);
  //   return clubs?.map((club) => club.filteredCampaigns({ ...query(), limit }));
  // }, [clubs?.length, filter, limit]);

  // useEffect(() => {
  //   console.log("filteredCampaigns", filteredCampaigns)
  //   filteredCampaigns?.forEach(v => {
  //     console.log("v==", v.docs)
  //   })
  //   const disposer = autorun(() => {
  //     const isLoaded = filteredCampaigns?.reduce(
  //       (acc, campaigns) =>
  //         acc &&
  //         campaigns.isLoaded &&
  //         campaigns.docs.reduce(
  //           (acc, campaign) => acc && campaign.isLoaded,
  //           true,
  //         ),
  //       true,
  //     );
  //     console.log("isLoaded",isLoaded)
  //     if (isLoaded) {
  //       setCampaigns(filteredCampaigns);
  //       console.log("filtered Campaigns", filteredCampaigns)
  //       setLoaded(true);
  //     }
  //   });
  // }, [filteredCampaigns, filteredCampaigns?.length, limit, filter]);

  // new one For filtering Campaigns from top Campaign collections if All leagues or Amatuer Football selected otherwise old collections

  // useEffect(() => {
  //   if (filter?.league?.title && filter.league.title !== "Amateurvoetbal") {
  //     const filteredCampaigns = clubs?.map((club) =>
  //       club.filteredCampaigns({ ...query(), limit }),
  //     );
  //     setLoaded(false);
  //     const disposer = autorun(() => {
  //       const isLoaded = filteredCampaigns?.reduce(
  //         (acc, campaigns) =>
  //           acc &&
  //           campaigns.isLoaded &&
  //           campaigns.docs.reduce(
  //             (acc, campaign) => acc && campaign.isLoaded,
  //             true,
  //           ),
  //         true,
  //       );
  //       if (isLoaded) {
  //         setCampaigns(filteredCampaigns);
  //         setLoaded(true);
  //       }
  //     });
  //     return () => {
  //       if (disposer) disposer();
  //     };
  //   }
  // }, [filter, limit]);

  const fetchAllCampaigns = async (filterBy: Filter) => {
    setLoaded(false);
    const allCampaigns: any = await data.country?.fetchAllCampaigns(
      { ...query(), limit },
      filterBy,
    );
    setCampaigns(allCampaigns);
    if (allCampaigns.length > 0) {
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchAllCampaigns(filter);
  }, [filter]);

  const items =
    campaigns
      ?.flatMap((campaign) => campaign.docs)
      .sort((a, b) => {
        const [field, direction] = query().orderBy || [];
        if (!field) return 0;
        return direction === "desc"
          ? // @ts-ignore
            b[field] - a[field]
          : // @ts-ignore
            a[field] - b[field];
      })
      .slice(0, limit) || [];

  return (
    <div className={classNames(isEmbed && "pt-0", "latest campaign")}>
      <div className="container align-center">
        {!isEmbed && (
          <>
            {limit && (
              <span className="float-left explore-all" style={{ opacity: 0 }}>
                {t("Check out all support actions")} &gt;
              </span>
            )}
            {/* {limit && (
              <Link to="explore" className="float-right explore-all">
                {t("Check out all support actions")} &gt;
              </Link>
            )} */}
            <h2 className="title">{t("Discover support actions")}</h2>

            <CampaignFilter filter={filter} hide={hide} onChange={setFilter} />
          </>
        )}
        {isLoaded ? (
          items.length > 0 ? (
            <Masonry className="row gx-4">
              {items?.map((campaign) => (
                <CampaignItem
                  newTab={isEmbed ?? false}
                  key={campaign.id}
                  campaign={campaign}
                />
              ))}
            </Masonry>
          ) : (
            <div className="empty">{t("No actions found")}</div>
          )
        ) : (
          <div className="discover-loader">
            <Preloader inline />
          </div>
        )}
        {!isEmbed && limit && (
          <div className="latest-button">
            <Link to="explore" className="btn-secondary me-0">
              {t("Check out all support actions")}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(Discover);
