import React from "react";

import { InfoCarousel, Item } from "./carousel";

interface Props {
  badge?: React.ReactNode;
  header?: string;
  content: Item[];
}
export const Info = ({ badge, header, content }: Props) => {
  return (
    <div className="for-fans py-5 bg-grey">
      <div className="container">
        <div className="text-center">
          {badge && (
            <span className="py-2 px-3 rounded text-center bg-clubmeister-blue text-white d-inline-flex align-items-center justify-content-between">
              {badge}
            </span>
          )}
          {header && (
            <h2 className="text-center mb-5 mt-3 d-flex justify-content-center align-items-center">
              {header}
            </h2>
          )}
        </div>
        <InfoCarousel items={content} />
      </div>
    </div>
  );
};
