import { useTranslation } from "react-i18next";

import PaymentsByMollie from "../../assets/images/Payments by Badge__2x.png";

const Disclaimer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="cart-totals border border-primary">
        <p className="disclaimer">
          {t(
            "You will get your money back if the club doesn't cash in the donations",
          )}
        </p>
      </div>
      <div className="text-center">
        <img className="w-auto" src={PaymentsByMollie} alt="iDEAL" />
      </div>
    </>
  );
};

export default Disclaimer;
