import React from "react";
import { Carousel } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

export type Item = {
  icon?: React.ReactNode;
  header?: string;
  text?: string;
  button?: () => void;
};

export type CarouselProps = {
  items: Item[];
};
export const InfoCarousel = ({ items }: CarouselProps) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  const renderItems = () => {
    if (isDesktop) {
      return (
        <div className="row">
          {items.map((item) => (
            <div className="col-lg-4" key={item.text}>
              <div className="item-work h-100">
                {item.icon && <div className="item-icon mb-2">{item.icon}</div>}
                {item.header && <h3 className="item-header">{item.header}</h3>}
                <div className="item-content">
                  <div className="item-desc">
                    <p>{item.text}</p>
                  </div>
                </div>
                {item.button}
              </div>
            </div>
          ))}
        </div>
      );
    }
    return (
      <Carousel
        prevIcon={<i className="fas fa-angle-left carousel-indicator" />}
        prevLabel={""}
        nextIcon={<i className="fas fa-angle-right carousel-indicator" />}
        nextLabel={""}
      >
        {items.map((item) => (
          <Carousel.Item key={item.text}>
            <div className="item-work">
              {item.icon && <div className="item-icon mb-2">{item.icon}</div>}
              {item.header && <h3 className="item-header">{item.header}</h3>}
              <div className="item-content">
                <div className="item-desc">
                  <p>{item.text}</p>
                </div>
              </div>
              {item.button}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  return renderItems();
};
