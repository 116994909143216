import { useTranslation } from "react-i18next";

import { Info } from "../general/info";

export const ForFans = () => {
  const { t } = useTranslation();

  const ITEMS = [
    {
      icon: <i className="fa fa-lightbulb" aria-hidden="true" />,
      header: t("home.forfans1.header"),
      text: t("home.forfans1.text"),
    },
    {
      icon: <i className="fa fa-bullhorn" aria-hidden="true" />,
      header: t("home.forfans2.header"),
      text: t("home.forfans2.text"),
    },
    {
      icon: <i className="fa fa-money-bill-wave" aria-hidden="true" />,
      header: t("home.forfans3.header"),
      text: t("home.forfans3.text"),
    },
  ];

  return (
    <Info
      badge={
        <>
          <i className="fa-solid fa-users fa-2x me-2"></i>
          <strong>{t("home.forfans.tag")}</strong>
        </>
      }
      header={t("home.forfans.header")}
      content={ITEMS}
    />
  );
};
