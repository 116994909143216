import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useState } from "react";
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import validator from "validator";

import CampaignComment from "./comment";
import { localizedError } from "../../helpers/error";
import { useStore, Campaign, Comment } from "../../store";
import Textarea from "../general/textarea";

interface Report {
  comment: Comment;
  text?: string;
}

interface Props {
  campaign?: Campaign;
}

const Comments = (props: Props) => {
  const { auth } = useStore();
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState({ value: "", isValid: true, message: "" });
  const [report, setReport] = useState<Report>();
  const [error, setError] = useState<string>();
  const { t } = useTranslation();
  const { campaign } = props;
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const reports = auth.user?.reports;

  const updateText = (value: string) => {
    setText({ ...text, value });
  };

  const isValid = () => {
    let isValid = true;
    let message = "";

    if (validator.isEmpty(text.value)) {
      isValid = false;
      message = t("Post a comment");
    }
    setText({ ...text, isValid, message });
    return isValid;
  };

  const postComment = async () => {
    if (isValid()) {
      try {
        if (!auth.isSignedIn) {
          await auth.signInAnonymously();
        }
        if (!auth.userId) throw new Error("Not authorized");
        const data = {
          text: text.value,
          userId: auth.userId,
        };
        await campaign?.addComment(data);
        runInAction(() => {
          setEdit(false);
          setText({ value: "", isValid: true, message: "" });
        });
      } catch (err) {
        setError(localizedError(err as Error, t));
      }
    }
  };

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const showForm = () => setEdit(true);

  const hideReport = () => {
    setReport(undefined);
  };

  const reportComment = async () => {
    if (!report) return;
    await auth.user?.reportComment(report);
    setReport(undefined);
  };

  const renderReport = () => (
    <Modal show={!!report} onHide={hideReport}>
      <Modal.Header closeButton>
        <Modal.Title>Report comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={t(
                "Describe the reason why you want to report this comment",
              )}
              onChange={(event) =>
                report && setReport({ ...report, text: event.target.value })
              }
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={hideReport}>
          <Trans>Cancel</Trans>
        </Button>
        <Button variant="primary" onClick={reportComment}>
          <Trans>Report</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const renderError = () => {
    if (error) {
      return (
        <div className="form-error alert alert-danger" role="alert">
          {error}
        </div>
      );
    }
  };

  const renderForm = () => {
    if (!edit) {
      return (
        <div id="respond" className="comment-respond">
          <Button
            variant="primary"
            onClick={showForm}
            className={!isDesktop ? "w-100" : undefined}
          >
            {t("Post a comment")}
          </Button>
        </div>
      );
    }

    return (
      <div id="respond" className="comment-respond">
        <form noValidate onSubmit={submit}>
          <div className="form-group">
            <label htmlFor="title">{t("Your comment")}</label>
            <Textarea
              invalid={!text.isValid}
              rows={8}
              placeholder=""
              value={text.value}
              onChange={updateText}
            />
            <span className="invalid-feedback">{text.message}</span>
          </div>
          <Button
            type="submit"
            variant="primary"
            onClick={postComment}
            className={!isDesktop ? "w-100" : undefined}
          >
            {t("Post a comment")}
          </Button>
          {renderError()}
        </form>
      </div>
    );
  };
  const comments =
    (reports?.docs
      ? campaign?.comments.docs.filter(
          (comment) =>
            (reports?.docs || []).map((doc) => doc.id).indexOf(comment.id) < 0,
        )
      : campaign?.comments.docs) || [];

  let content;
  if (!campaign?.comments.isLoaded || (reports && !reports?.isLoaded)) {
    content = null;
  } else if (!comments.length)
    content = <div className="empty">{t("No reactions until now")}</div>;
  else {
    content = (
      <ol className="comments-list">
        {comments.map((comment) => (
          <CampaignComment
            key={comment.id}
            comment={comment}
            onReport={() => setReport({ comment })}
          />
        ))}
      </ol>
    );
  }
  return (
    <Container>
      <Row>
        <Col id="comment" className="comment-area">
          {renderForm()}
          {content}
          {renderReport()}
        </Col>
      </Row>
    </Container>
  );
};

export default observer(Comments);
