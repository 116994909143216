import React from "react";
import { useAsync } from "react-async-hook";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import { useStore } from "../../store";

const Sideshow = () => {
  const { t } = useTranslation();
  const { data } = useStore();
  const image = useAsync(data.headerImage, []);
  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });

  return (
    <div
      className="sideshow"
      style={{ backgroundImage: `url(${image.result})` }}
    >
      <div className="container">
        <div className="sideshow-content">
          <h1 className="wow fadeInUp" data-wow-delay=".1s">
            {t("Your voice, your support, your club")}
          </h1>
          <div
            className="sideshow-description wow fadeInUp"
            data-wow-delay=".1s"
          >
            {t("Collect money for support actions for your favourite club")}
          </div>
          {isMobile && (
            <div className="button wow fadeInUp" data-wow-delay="0.1s">
              <Link to="explore" className="btn-primary large">
                {t("Donate")}
              </Link>
            </div>
          )}
          <div className="button wow fadeInUp mt-3" data-wow-delay="0.1s">
            <Link to="form" className="btn-primary large">
              {t("Start Campaign")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sideshow;
