// eslint-disable-next-line no-unused-vars
import React from "react";
import ReactDOM from "react-dom";

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "./styles/style.css";
import "./styles/responsive.css";

// eslint-disable-next-line no-unused-vars
import App from "./app";
import registerServiceWorker from "./helpers/serviceworker";

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
