import { observer } from "mobx-react-lite";
import React from "react";
import { useAsync } from "react-async-hook";
import { Spinner } from "react-bootstrap";

import AdminFund from "../../components/admin/fund";
import { useStore } from "../../store";

const Fund = () => {
  const { data } = useStore();
  const { result, loading } = useAsync(async () => {
    const [funds, profiles, campaigns] = await Promise.all([
      data.admin.allFunds(),
      data.admin.profiles(),
      data.admin.campaigns(),
    ]);
    return { funds, profiles, campaigns };
  }, []);

  if (loading)
    return (
      <div className="text-center p-4">
        <Spinner animation="border" role="status" />
      </div>
    );

  return (
    <div className="admin">
      <AdminFund
        funds={result?.funds || []}
        profiles={result?.profiles || []}
        campaigns={result?.campaigns || []}
        fundPage={false}
      />
    </div>
  );
};

export default observer(Fund);
