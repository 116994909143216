import i18n from "i18next";
import { observer } from "mobx-react-lite";
import numeral from "numeral";
import React, { useMemo, useReducer, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Campaign, Fund, Profile, useStore } from "../../store";
import Select from "../general/select";

interface Props {
  funds: Fund[];
  profiles: Profile[];
  campaigns: Campaign[];
  fundPage: boolean;
}

const AdminFund = ({ funds, profiles, campaigns, fundPage }: Props) => {
  const { data } = useStore();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<false | string>(false);
  const [error, setError] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [refund, setRefund] = useState<Fund | undefined>();
  const [showConfirm, setShowConfirm] = useState(false);
  const [refundCampaign, setRefundCampaign] = useState<Campaign | undefined>();
  const statuses = [
    "Open",
    "Paid",
    "Canceled",
    "Expired",
    "Verified",
    "Failed",
  ];

  const [filters, setFilters] = useReducer(
    (state: any, updates: any) => ({ ...state, ...updates }),
    { user: "", club: "", campaign: "", status: "" },
  );

  const filteredFunds = useMemo(() => {
    return funds.filter((fund) => {
      const { user, club, campaign, status } = filters;
      return (
        (!user || fund?.campaign?.user?.id === user) &&
        (!club || fund?.campaign?.club?.id === club) &&
        (!campaign || fund?.campaign?.id === campaign) &&
        (!status || fund?.status?.toLowerCase() === status.toLowerCase())
      );
    });
  }, [funds, filters]);

  let totalPaidAmount = 0;
  const seenCampaignIds = new Set();

  for (const fund of filteredFunds) {
    if (fund.status?.toLowerCase() !== "paid") continue;

    const campaignId = fund.campaign?.id;
    if (!campaignId || seenCampaignIds.has(campaignId)) continue;

    seenCampaignIds.add(campaignId);
    totalPaidAmount += fund.campaign.raised || 0;
  }

  const showRefundAll = useMemo(() => {
    return filteredFunds.some(
      (fund) =>
        fund.isLoaded &&
        !fund.isOpen &&
        !fund.refundStatus &&
        !fund.campaign?.data.cashed,
    );
  }, [filteredFunds]);

  const createRefund = async (fund: Fund | undefined) => {
    if (!fund) return;
    setError("");
    setLoading(fund.id || false);
    setConfirmModal(false);
    try {
      await data.admin.createRefund(fund);
    } catch (error) {
      setError((error as Error).message);
    }
    setLoading(false);
  };

  const campaignRefund = async () => {
    setError("");
    setLoading("*");
    setShowConfirm(false);
    try {
      refundCampaign && (await data.admin.refundCampaign(refundCampaign));
    } catch (error) {
      setError((error as Error).message);
    }
    setLoading(false);
  };

  const renderCampaign = (campaign: Campaign, donor?: Profile) => {
    const path = campaign.link.replace(
      `/${data.country?.id}`,
      `/${data.country?.id}/admin`,
    );
    const { club, title } = campaign;
    return (
      <Row>
        <Col sm="auto">
          <div className="p-2" style={{ width: "50px", height: "50px" }}>
            <img
              className="logo img-fluid"
              src={club?.logo}
              alt={t("Club")}
              style={{ objectFit: "contain" }}
            />
          </div>
        </Col>
        <Col>
          <div>
            <Link
              to={`/${data.country?.id}/admin/user/${donor?.id}`}
              className="no-wrap"
            >
              <span className="campaign-user">
                {donor?.displayName || t("Anonymous")}
              </span>
            </Link>
          </div>
          <Link to={path}>{title}</Link>
        </Col>
      </Row>
    );
  };

  const renderFund = (fund: Fund) => {
    const { campaign, amount, refundStatus, status, createdAt, user } = fund;
    if (
      !campaign ||
      !campaign.user?.id ||
      !campaign.club?.logo ||
      !campaign.isPublished
    )
      return null;
    const currencyPrefix = campaign.club.league?.country?.displayCurrencyPrefix;
    const refundAction = !campaign.data.cashed &&
      fund.isCompleted &&
      fund.isLoaded &&
      !refundStatus && (
        <Button
          size="sm"
          onClick={() => {
            setRefund(fund);
            setConfirmModal(true);
          }}
          className="float-right"
          disabled={loading === fund.id}
        >
          {loading === fund.id ? (
            <Trans>Loading...</Trans>
          ) : (
            <Trans>Refund</Trans>
          )}
        </Button>
      );
    const refundBadge = refundStatus && (
      <Badge
        bg={
          refundStatus === "succeeded"
            ? "primary"
            : refundStatus === "pending"
              ? "warning"
              : "danger"
        }
        className="float-right"
      >
        <Trans>
          {refundStatus === "succeeded"
            ? "Refunded"
            : refundStatus === "pending"
              ? "Refund pending"
              : "Refund failed"}
        </Trans>
      </Badge>
    );

    return (
      <tr key={fund.id}>
        <td>{renderCampaign(campaign, user)}</td>
        <td>
          {currencyPrefix} {numeral(amount / 100).format("0,0.00")}
          <Badge
            className="ms-2"
            bg={
              status === "paid"
                ? "success"
                : status === "canceled"
                  ? "danger"
                  : "secondary"
            }
          >
            {status}
          </Badge>
        </td>
        <td>
          {createdAt
            ? new Date(createdAt).toLocaleString(i18n.language || "nl-NL")
            : ""}
          {refundBadge || refundAction}
        </td>
      </tr>
    );
  };

  return (
    <div className="container">
      {error && (
        <Alert variant="danger" onClose={() => setError("")} dismissible>
          {error}
        </Alert>
      )}

      {!fundPage && (
        <Form.Group controlId="filterData">
          <Row>
            <Col>
              <Form.Label>
                <Trans>Status</Trans>
              </Form.Label>
              <InputGroup>
                <Select
                  onChange={(val: string) => setFilters({ status: val })}
                  value={filters.status}
                >
                  <option key="empty" value="">
                    {t("Select a status")}
                  </option>
                  {statuses?.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Select>
              </InputGroup>
            </Col>
            <Col>
              <Form.Label>
                <Trans>Campaigns</Trans>
              </Form.Label>
              <InputGroup>
                <Select
                  onChange={(val: string) => {
                    const camp = campaigns.find((c) => c.id === val);
                    setFilters({ campaign: val });
                    setRefundCampaign(camp);
                  }}
                  value={filters.campaign}
                >
                  <option key="empty" value="">
                    {t("Select a campaign")}
                  </option>
                  {campaigns.map((campaign: Campaign) => (
                    <option value={campaign.id} key={campaign.id}>
                      {campaign.title}
                    </option>
                  ))}
                </Select>
              </InputGroup>
            </Col>
            <Col>
              <Form.Label>
                <Trans>Users</Trans>
              </Form.Label>
              <InputGroup>
                <Select
                  onChange={(val: string) => setFilters({ user: val })}
                  value={filters.user}
                >
                  <option key="empty" value="">
                    {t("Select a user")}
                  </option>
                  {profiles.map((profile) => (
                    <option value={profile.id} key={profile.id}>
                      {profile.displayName}
                    </option>
                  ))}
                </Select>
              </InputGroup>
            </Col>
            <Col className="d-flex align-items-end">
              <Button
                variant="light"
                size="sm"
                onClick={() =>
                  setFilters({ user: "", club: "", campaign: "", status: "" })
                }
              >
                <Trans>Reset</Trans>
              </Button>
            </Col>
            {filters.campaign && filteredFunds.length > 0 && showRefundAll && (
              <Col className="d-flex align-items-end">
                <Button
                  onClick={() => setShowConfirm(true)}
                  size="sm"
                  variant="primary"
                  disabled={!!loading}
                >
                  {loading ? t("Loading...") : t("Refund all")}
                </Button>
              </Col>
            )}
          </Row>
          {filteredFunds.length > 0 && (
            <>
              <Row className="mt-3">
                <Col md={4}>
                  <h6>
                    <Trans>Total number of donations</Trans>:{" "}
                    {filteredFunds.length}
                  </h6>
                </Col>
                <Col md={4}>
                  <h6>
                    <Trans>Total value of paid amount</Trans>:{" "}
                    {numeral(totalPaidAmount / 100).format("0,0[.]00")}
                  </h6>
                </Col>
              </Row>
            </>
          )}
        </Form.Group>
      )}

      <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>Refund donation</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans>
            Are you sure you want to refund donation? This action cannot be
            reversed.
          </Trans>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => setConfirmModal(false)}>
            <Trans>Cancel</Trans>
          </Button>
          <Button variant="danger" onClick={() => createRefund(refund)}>
            <Trans>Refund</Trans>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>Refund all donations</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans>
            Are you sure you want to refund all donations? This action cannot be
            reversed.
          </Trans>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => setShowConfirm(false)}>
            <Trans>Cancel</Trans>
          </Button>
          <Button variant="danger" onClick={campaignRefund}>
            <Trans>Refund all</Trans>
          </Button>
        </Modal.Footer>
      </Modal>

      {funds.length === 0 ? (
        <div className="text-center p-4">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : filteredFunds.length === 0 ? (
        <div className="data-empty">{t("No funds found")}</div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t("Campaign")}</th>
              <th>{t("Amount")}</th>
              <th>{t("Date")}</th>
            </tr>
          </thead>
          <tbody>{filteredFunds.map(renderFund)}</tbody>
        </Table>
      )}
    </div>
  );
};

export default observer(AdminFund);
