import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { League } from "../../../store";

interface Props {
  leagues: League[];
}
const AdminLeague = (props: Props) => {
  const { leagues } = props;
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="head">
        <Link to="new" className="btn btn-primary">
          {t("Add league")}
        </Link>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t("League")}</th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {leagues.map((league) => (
            <tr className="campaign" key={league.id}>
              <td>
                <Row>
                  <Col className="col-middle">
                    <Link to={`${league.id}/club`}>
                      <img
                        className="logo"
                        src={league?.logo}
                        alt={t("Club")}
                      />
                      {league.title}
                    </Link>
                  </Col>
                </Row>
              </td>
              <td className="col-fit">
                <Link
                  className="btn btn-sm btn-primary me-2"
                  to={league.id || ""}
                >
                  {t("Edit")}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AdminLeague;
