import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Club } from "../../../store";

interface Props {
  clubs: Club[];
}
const AdminClub = (props: Props) => {
  const { clubs } = props;
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="head">
        <Link to="newAll" className="btn btn-primary me-2">
          {t("Add Xlsx ")}
        </Link>
        <Link to="new" className="btn btn-primary">
          {t("Add club")}
        </Link>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t("Club")}</th>
          </tr>
        </thead>
        <tbody>
          {clubs.map((club) => (
            <tr className="campaign" key={club.id}>
              <td>
                <Link to={club.id || ""}>
                  <img className="logo" src={club?.logo} alt={t("Club")} />
                  {club.title}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AdminClub;
