import React, { useEffect, useState } from "react";
import { Alert, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import validator from "validator";

import { MessageData, useStore } from "../../store";
import Input from "../general/input";
import Textarea from "../general/textarea";

interface Errors extends Partial<MessageData> {
  general?: string;
}

const Form = () => {
  const [data, setData] = useState<Partial<MessageData>>({});
  const [errors, setErrors] = useState<Errors>({});
  const [completed, setCompleted] = useState(false);
  const store = useStore();
  const { t } = useTranslation();
  const [contactEmail, setContactEmail] = useState("");

  useEffect(() => {
    if (store?.data?.country?.data?.contacts) {
      setContactEmail(store?.data?.country?.data?.contacts[0].email);
    }
  }, [store?.data?.country]);

  const changeIssueType = (email: string) => () => {
    setContactEmail(email);
  };

  const update =
    (key: "name" | "email" | "subject" | "message") => (value: string) =>
      setData({ ...data, [key]: value });

  const send = async () => {
    try {
      const { name, email, subject, message } = data;
      const newErrors: Partial<MessageData> = {};
      if (!name || validator.isEmpty(name))
        newErrors.name = t("Enter your name");
      if (!email || validator.isEmpty(email))
        newErrors.email = t("Enter your email");
      else if (!validator.isEmail(email))
        newErrors.email = t("Email is invalid");
      if (!subject || validator.isEmpty(subject))
        newErrors.subject = t("Enter a subject");
      if (!message || validator.isEmpty(message))
        newErrors.message = t("Enter a message");
      setErrors(newErrors);
      if (Object.keys(newErrors).length > 0) return;
      data.toMail = contactEmail;
      await store.data.sendMessage(data as MessageData);
      setCompleted(true);
    } catch (error) {
      const { message } = error as Error;
      console.error("Message failed: ", message);
      setErrors({ general: t("Something went wrong please try again") });
    }
  };

  if (completed) {
    return (
      <div className="form-contact">
        <h2>
          <Trans>You message was sent succesful</Trans>
        </h2>
        <p>
          <Trans>
            Thank you for your message. We will respond to you as soon as
            possible
          </Trans>
        </p>
      </div>
    );
  }

  return (
    <div className="form-contact">
      <h2>
        <Trans>Drop us a line</Trans>
      </h2>
      <form action="s" method="POST" id="contactForm" className="clearfix">
        <div className="clearfix">
          <div className="field align-left">
            <Input
              value={data.name || ""}
              placeholder={t("Your Name")}
              onChange={update("name")}
              invalid={!!errors.name}
            />
            <span className="invalid-feedback">{errors.name}</span>
          </div>
          <div className="field align-right">
            <Input
              value={data.email || ""}
              placeholder={t("Your Email")}
              onChange={update("email")}
              invalid={!!errors.email}
            />
            <span className="invalid-feedback">{errors.email}</span>
          </div>
        </div>
        <div className="field">
          <Input
            value={data.subject || ""}
            placeholder={t("Subject")}
            onChange={update("subject")}
            invalid={!!errors.subject}
          />
          <span className="invalid-feedback">{errors.subject}</span>
        </div>
        <div className="field-textarea">
          <Textarea
            rows={8}
            placeholder={t("Message")}
            onChange={update("message")}
            invalid={!!errors.message}
          >
            {data.message}
          </Textarea>
          <DropdownButton
            variant="outline-secondary"
            title={
              store?.data?.country?.data?.contacts
                ? store?.data?.country?.data?.contacts[0].value
                : ""
            }
            className="mt-3"
          >
            {store?.data?.country?.data?.contacts?.map((issue: any) => (
              <Dropdown.Item
                key={issue.value}
                onClick={changeIssueType(issue.email)}
              >
                {issue.value}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <span className="invalid-feedback">{errors.message}</span>
        </div>
        <Button onClick={send}>{t("Submit message")}</Button>
        {errors.general && <Alert>{errors.general}</Alert>}
      </form>
    </div>
  );
};

export default Form;
