import classNames from "classnames";
import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ChangeEvent,
} from "react";
import { InputGroup } from "react-bootstrap";

interface Props
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "onChange"
  > {
  invalid?: boolean;
  prepend?: string;
  append?: string;
  onChange?: (value: string) => void;
}
const Input = (props: Props) => {
  const { invalid, onChange, className, prepend, append, ...otherProps } =
    props;
  const inputClass = classNames(
    "form-control",
    {
      "is-invalid": invalid,
    },
    className,
  );

  const update = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };
  return (
    <InputGroup>
      {prepend && <InputGroup.Text>{prepend}</InputGroup.Text>}
      <input className={inputClass} onChange={update} {...otherProps} />
      {append && <InputGroup.Text>{append}</InputGroup.Text>}
    </InputGroup>
  );
};

export default Input;
