import React from "react";

import Menu from "./menu";

const Top = () => {
  return (
    <header id="header" className="site-header">
      <Menu />
    </header>
  );
};

export default Top;
