import moment from "moment";
import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

import Config from "../../config.json";

export const COOKIE_BETA_ACCESS = "beta-access";

const Password = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const setCookie = useCookies([COOKIE_BETA_ACCESS])[1];

  const update = (event: ChangeEvent<HTMLInputElement>) => {
    setError("");
    setPassword(event.target.value);
  };

  const verify = () => {
    if ((Config as any).beta && (Config as any).beta?.password === password) {
      setCookie(COOKIE_BETA_ACCESS, password, {
        expires: moment().add(1, "year").toDate(),
      });
      window.location.reload();
    } else {
      setError(t("Invalid password"));
    }
  };

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className="container">
      <div className="main-content content-login">
        <div className="form-login">
          <h1>{t("Beta access")}</h1>
          <form noValidate onSubmit={submit}>
            <div className="field">
              <input
                type="password"
                value={password}
                name="password"
                placeholder={t("Password")}
                onChange={update}
              />
            </div>
            <div className="inline clearfix">
              <Button type="submit" variant="primary" onClick={verify}>
                {t("Next")}
              </Button>
            </div>
            {error && (
              <div className="form-error alert alert-danger" role="alert">
                {error}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Password;
