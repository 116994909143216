import classNames from "classnames";
import { InputGroup } from "react-bootstrap";
import DatePicker, { DatePickerProps } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useStore } from "../../store";

type Props = DatePickerProps & {
  invalid?: boolean;
  prepend?: string;
  append?: string;
  className?: string;
};
const DateInput = (props: Props) => {
  const { invalid, className, prepend, append, ...otherProps } = props;
  const inputClass = classNames(
    "form-control",
    {
      "is-invalid": invalid,
    },
    className,
  );
  const { i18n } = useStore();

  return (
    <InputGroup>
      {prepend && <InputGroup.Text>{prepend}</InputGroup.Text>}
      <DatePicker
        className={inputClass}
        {...otherProps}
        dateFormat="PPP"
        locale={i18n.locale}
      />
      {append && <InputGroup.Text>{append}</InputGroup.Text>}
    </InputGroup>
  );
};

export default DateInput;
