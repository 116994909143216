import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useMemo } from "react";
import { useAsync } from "react-async-hook";
import { Row, Col, Button, Badge } from "react-bootstrap";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { Profile, ProviderData, useStore } from "../../../store";
import Avatar from "../../general/avatar";

interface Props {
  profile: Profile;
}
const UserProfile = (props: Props) => {
  const { profile } = props;
  const { id, displayName, providerData, createdAt, email } = profile;
  const { data } = useStore();
  const user = useMemo(
    () => (id ? data.admin.user(id) : undefined),
    [id, data.admin],
  );
  const providerAccount = useAsync(
    async () => providerData && (await profile.providerAccount(providerData)),
    [providerData],
  );

  const renderProvider = (providerData?: ProviderData) => {
    let icon;
    let url;
    switch (providerData?.providerId) {
      case "password":
        icon = <i className="far fa-envelope"></i>;
        break;
      case "twitter.com": {
        if (providerAccount.result) {
          const { username } = providerAccount.result;
          url = `https://twitter.com/${username}`;
        }
        icon = <i className="fa-brands fa-x-twitter"></i>;
        break;
      }
      case "facebook.com": {
        if (providerAccount.result) {
          const { link } = providerAccount.result;
          url = link;
        }
        icon = <i className="fab fa-facebook-f"></i>;
        break;
      }
      case "google.com":
        icon = <i className="fab fa-google"></i>;
    }
    if (url) {
      return (
        <Button
          className="btn-round"
          variant="light"
          href={url}
          target="_blank"
        >
          {icon}
        </Button>
      );
    } else if (email) {
      return (
        <Button className="btn-round" variant="light" href={`mailto:${email}`}>
          {icon}
        </Button>
      );
    } else if (!providerAccount.loading) {
      return (
        <Button className="btn-round" variant="outline-danger">
          {icon}
        </Button>
      );
    }
    return icon;
  };

  return (
    <tr className="user" key={id}>
      <td>
        <Row>
          <Col sm="auto">
            <Avatar user={profile} />
          </Col>
          <Col>
            <Link to={id || ""}>
              <div>
                <span className="created-date">
                  {createdAt ? moment(createdAt).fromNow() : ""}
                </span>
              </div>
              {displayName}
              {email && ` (${email})`}
            </Link>
          </Col>
        </Row>
      </td>
      <td className="col-fit">{renderProvider(providerData)}</td>
      <td className="col-fit">
        {user?.blocked ? (
          <Badge bg="danger">
            <Trans>Blocked</Trans>
          </Badge>
        ) : user?.isLoaded ? (
          <Badge bg="success">
            <Trans>Active</Trans>
          </Badge>
        ) : null}
      </td>
    </tr>
  );
};

export default observer(UserProfile);
