import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState, forwardRef } from "react";
import { Container, Row, Col, Dropdown, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, NavLink, useLocation } from "react-router-dom";

import Avatar from "./avatar";
import LanguageSelector from "./languages";
import Logo from "../../assets/images/logo.svg";
import { useStore } from "../../store";

const TopMenu = () => {
  const [open, setOpen] = useState(false);
  const { auth, data } = useStore();
  const { profile, isAdmin, isSignedIn } = auth;
  const location = useLocation();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });

  const closeMenu = () => {
    setOpen(false);
  };

  const toggleMenu = () => {
    setOpen(!open);
  };

  const renderAccountButton = forwardRef((props, ref) => {
    // @ts-ignore
    const { onClick } = props;
    return (
      <Link
        to="#"
        // @ts-ignore
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <Avatar user={profile} size={40} showName={true} />
      </Link>
    );
  });

  const renderAccount = () => {
    if (isSignedIn && profile) {
      return (
        <Dropdown align="end">
          <Dropdown.Toggle as={renderAccountButton}>Account</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <div className="user">
                <span>{profile.displayName}</span>
                <span className="email">{profile.email}</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href={`/${data.country?.id}/profile`}>
              {t("menu.profile")}
            </Dropdown.Item>
            {isAdmin && (
              <Dropdown.Item href={`/${data.country?.id}/admin`}>
                {t("menu.admin")}
              </Dropdown.Item>
            )}
            <Dropdown.Divider />
            <Dropdown.Item href="#" onClick={auth.signOut}>
              {t("menu.signout")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  };

  const renderAccountMenu = () => {
    if (isSignedIn && profile) {
      return (
        <li>
          <NavDropdown
            title={t("menu.account")}
            id="nav-dropdown"
            className="no-carret"
          >
            <NavDropdown.Item>
              <div className="user">
                <span>{profile.displayName}</span>
                <span className="email">{profile.email}</span>
              </div>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href={`/${data.country?.id}/profile`}>
              {t("menu.profile")}
            </NavDropdown.Item>
            {isAdmin && (
              <NavDropdown.Item href="admin">{t("Admin")}</NavDropdown.Item>
            )}
            <NavDropdown.Divider />
            <NavDropdown.Item href="#" onClick={auth.signOut}>
              {t("menu.signout")}
            </NavDropdown.Item>
          </NavDropdown>
        </li>
      );
    }
  };

  const renderSignIn = () => {
    if (!isSignedIn) {
      const path = location.pathname;
      const to = "/signin" + (path ? `?from=${path}` : "");
      return (
        <div className="login login-button">
          <Link to={to} className="btn-primary">
            <i className="far fa-user" aria-hidden="true" />
            <span>{t("menu.login")}</span>
          </Link>
        </div>
      );
    }
  };

  const renderSignInMenu = () => {
    if (!isSignedIn) {
      const path = location.pathname;
      const to = "/signin" + (path ? `?from=${path}` : "");
      return (
        <li>
          <Link to={to} onClick={closeMenu}>
            {t("menu.login")}
          </Link>
        </li>
      );
    }
  };

  const hamburgerClass = classNames("c-hamburger", "c-hamburger--htx", {
    "is-active": open,
  });
  const menuClass = classNames("main-menu", { open });
  return (
    <Container>
      <Row>
        {/* LOGO */}
        <Col xs={{ span: "auto", order: 2 }} lg={{ span: "auto", order: 1 }}>
          <div className="site-brand">
            <Link to=".">
              <img src={Logo} alt="Clubmeister" />
            </Link>
          </div>
        </Col>
        {/* MENU */}
        <Col
          className="text-center m-auto"
          xs={{ order: 1, span: "auto" }}
          lg={{ order: 2 }}
        >
          <nav className={menuClass}>
            <button className={hamburgerClass} onClick={toggleMenu}>
              <span />
            </button>
            <ul>
              <li className="d-none">
                <Link to="." onClick={closeMenu}>
                  {t("menu.home")}
                </Link>
              </li>
              <li className="d-lg-none">
                <Link to="info" onClick={closeMenu}>
                  {t("menu.howItWorks")}
                </Link>
              </li>
              <li>
                <NavLink to="form" onClick={closeMenu}>
                  {t("menu.startAction")}
                </NavLink>
              </li>
              <li>
                <NavLink to="explore" onClick={closeMenu}>
                  {t("menu.discover")}
                </NavLink>
              </li>
              <li className="d-lg-none">
                <Link to="about" onClick={closeMenu}>
                  {t("menu.about")}
                </Link>
              </li>
              <li className="d-lg-none">
                <Link to="faq" onClick={closeMenu}>
                  {t("FAQ")}
                </Link>
              </li>
              {isMobile && (
                <>
                  {renderAccountMenu()}
                  {renderSignInMenu()}
                </>
              )}
            </ul>
          </nav>
        </Col>
        {/* DONATE BUTTON */}
        {!isMobile && (
          <Col className="d-none" xs={{ span: "auto", order: 3 }}>
            <div className="login login-button donate m-auto">
              <Link to="explore" className="btn-primary">
                {t("Donate")}
              </Link>
            </div>
          </Col>
        )}
        {/* LANGUAGE PICKER */}
        <Col xs={{ span: "auto", order: 4 }}>
          <LanguageSelector />
        </Col>
        {/* LOGIN MENU */}
        {!isMobile && (
          <Col xs={{ span: "auto", order: 5 }}>
            {renderAccount()}
            {renderSignIn()}
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default observer(TopMenu);
