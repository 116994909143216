import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Filter } from "./discover";
import { useStore } from "../../store";

interface FilterButton {
  key: string;
  title: string;
  onSelect: () => void;
}

interface Props {
  hide?: ("club" | "league")[];
  filter?: Filter;
  onChange?: (filter: any) => void;
}
const CampaignFilter = (props: Props) => {
  const { filter, hide, onChange } = props;
  const { data } = useStore();
  const { t } = useTranslation();
  const hideLeagues = (hide || []).indexOf("league") >= 0;
  const hideClubs =
    !filter?.league ||
    (hide || []).indexOf("club") >= 0 ||
    filter?.league?.title === "Amateurvoetbal";
  const leagues = !hideLeagues ? data.country?.leagues.docs : undefined;
  const clubs = useMemo(
    () =>
      !hideClubs && filter?.league ? filter.league.clubs?.docs : undefined,
    [filter?.league, hideClubs],
  );

  const selectFilter = (filter: Filter) => () => {
    onChange && onChange(filter);
  };

  const renderFilter = (data: FilterButton, selected?: string) => {
    const classes = classNames("button", {
      "is-checked": selected === data.key,
    });
    return (
      <button
        key={data.key}
        className={classes}
        data-filter={data.key}
        onClick={data.onSelect}
      >
        {data.title}
      </button>
    );
  };

  const renderTypes = () => {
    const types = [
      {
        key: "all",
        title: t("All"),
        onSelect: selectFilter({ ...filter, type: "all" }),
      },
      {
        key: "popular",
        title: t("Popular"),
        onSelect: selectFilter({ ...filter, type: "popular" }),
      },
      {
        key: "new",
        title: t("New"),
        onSelect: selectFilter({ ...filter, type: "new" }),
      },
      {
        key: "cashed",
        title: t("Cashed"),
        onSelect: selectFilter({ ...filter, type: "cashed" }),
      },
    ];
    return types.map((type) => renderFilter(type, filter?.type || "all"));
  };

  const renderLeagues = () => {
    if (hideLeagues) return;
    const selected = filter?.league?.id;
    return [
      {
        key: "all",
        title: t("All leagues"),
        onSelect: selectFilter({
          ...filter,
          club: undefined,
          league: undefined,
        }),
      },
      ...(leagues || [])
        .slice()
        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        .map((league) => ({
          key: league.id || "",
          title: league.title,
          onSelect: selectFilter({ ...filter, league, club: undefined }),
        })),
    ].map((data) => renderFilter(data, selected || "all"));
  };

  const renderClubs = () => {
    if (hideClubs) return;
    const selected = filter?.club?.id;
    return [
      {
        key: "all",
        title: t("All clubs"),
        onSelect: selectFilter({ ...filter, club: undefined }),
      },
      ...(clubs || [])
        .map((club) => ({
          key: club.id || "",
          title: club.title,
          onSelect: selectFilter({ ...filter, club }),
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    ].map((data) => renderFilter(data, selected || "all"));
  };

  return (
    <div>
      <div className="campaign-tabs filter-theme">{renderTypes()}</div>
      <div className="campaign-tabs filter-theme">{renderLeagues()}</div>
      <div className="campaign-tabs filter-theme mobile-fixed">
        {renderClubs()}
      </div>
    </div>
  );
};

export default observer(CampaignFilter);
