import classNames from "classnames";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import {
  Button,
  Row,
  Col,
  Popover,
  OverlayTrigger,
  Nav,
} from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import sanitizeHtml from "sanitize-html";

import { ProfileVerified, Comment } from "../../store";
import Avatar from "../general/avatar";

interface Props {
  comment: Comment;
  onReport?: () => void;
}
const CampaignComment = (props: Props) => {
  const { comment, onReport } = props;
  const { user, text, createdAt } = comment;
  const { t } = useTranslation();

  const classes = classNames("comment", "clearfix", {
    verified: user?.isVerified,
  });

  const verifiedName = (verified?: ProfileVerified) => {
    switch (verified) {
      case "club":
        return t("Club");
      case "supporters":
        return t("Supporters association");
      default:
        return "";
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <Nav defaultActiveKey="/home" className="flex-column">
          <Nav.Link eventKey="report" onClick={onReport}>
            <Trans>Report comment</Trans>
          </Nav.Link>
        </Nav>
      </Popover.Body>
    </Popover>
  );
  return (
    <li className={classes}>
      <div className="comment-body">
        <div className="comment-avatar">
          <Avatar user={user} />
        </div>
        <div className="comment-info">
          <Row>
            <Col className="comment-header">
              <cite className="comment-author">
                {user?.displayName || t("Anonymous")}
              </cite>
              {user?.isVerified && (
                <div className="comment-verified">
                  <span>
                    <i className="fas fa-certificate">
                      <i className="fas fa-check inline" />
                    </i>{" "}
                    {verifiedName(user?.verified)}
                  </span>
                </div>
              )}
              <span className="comment-date">
                {createdAt ? moment(createdAt).fromNow() : ""}
              </span>
            </Col>
            <Col sm="auto">
              <OverlayTrigger
                trigger="click"
                placement="top-end"
                overlay={popover}
                rootClose
              >
                <Button variant="light" className="btn-round">
                  <i className="fas fa-ellipsis-h"></i>
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
          <div className="comment-content">
            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }} />
          </div>
        </div>
      </div>
    </li>
  );
};

export default observer(CampaignComment);
