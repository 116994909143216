import { observer } from "mobx-react-lite";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import {
  Button,
  Carousel,
  Col,
  Row,
  InputGroup,
  Spinner,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import sanitizeHtml from "sanitize-html";

import Share from "./share";
import { localizedError } from "../../helpers/error";
import { useStore, Campaign } from "../../store";
import Input from "../general/input";

const DEFAULT_FUNDS = [10, 20, 50];

interface Props {
  campaign: Campaign;
}
const CampaignSummary = (props: Props) => {
  const { campaign } = props;
  const { auth } = useStore();
  const [fundActive, setFundActive] = useState(false);
  const [fundOther, setFundOther] = useState(false);
  // const [didLike, setDidLike] = useState(campaign.didLike);
  const [showShare, setShowShare] = useState(false);
  const [funding, setFunding] = useState(0);
  const [fund, setFund] = useState({ value: 0, isValid: true, message: "" });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    fundings,
    raised,
    goal,
    images,
    club,
    title,
    description,
    user,
    createdAt,
    // likes,
    endAt,
    videoUrl,
  } = campaign;
  const progress = goal ? raised / goal : 0;
  const country = campaign.club?.league?.country;
  const currencyPrefix = country?.displayCurrencyPrefix;
  const minFund = country?.minFund;
  const maxFund = country?.maxFund;

  useEffect(() => {
    if (!campaign.title) {
      navigate("/");
    }
  }, [campaign.title, navigate]);

  // const like = async () => {
  //   try {
  //     if (!auth.isSignedIn) {
  //       await auth.signInAnonymously();
  //     }
  //     setDidLike(!didLike);
  //     await campaign.addLike();
  //   } catch (error) {
  //     const { message } = error as Error;
  //     console.error("Like error: ", message);
  //   }
  // };

  const updateFund = (value: string) => {
    const numberValue = parseFloat(value);
    setFund({ ...fund, value: numberValue });
  };

  const isValid = () => {
    let isValid = true;
    let message = "";
    if (!fund.value) {
      isValid = false;
      message = t("Enter an amount.");
    } else if (minFund && fund.value < minFund / 100) {
      isValid = false;
      message = t("Minimum amount for donation is {{currency}} {{amount}}", {
        currency: currencyPrefix,
        amount: minFund / 100,
      });
    } else if (maxFund && fund.value > maxFund / 100) {
      isValid = false;
      message = t(
        "This donation amount cannot be processed on this website, please contact us at info@club-meister and we will be pleased to help you with your donation",
      );
    }
    setFund({ ...fund, isValid, message });
    return isValid;
  };

  const startFund = () => {
    if (isValid()) {
      checkout(fund.value);
    }
  };

  const defaultFund = (value: number) => () => {
    updateFund(`${value}`);
    checkout(value);
  };

  const checkout = async (value: number) => {
    setFunding(value);
    try {
      if (!auth.isSignedIn) {
        await auth.signInAnonymously();
      }
      if (!auth.userId) throw new Error("Not authorized");
      const fundId = await campaign.addFund({
        amount: value * 100,
        currency: "eur",
      });
      navigate(`${campaign.link}/fund/${fundId}`);
    } catch (error) {
      setFund({ ...fund, message: localizedError(error as Error, t) });
    }
    setFunding(0);
  };

  const showFundActive = () => {
    setFundActive(true);
  };

  const showFundOther = () => {
    setFundOther(true);
  };

  const renderFunding = () => {
    if (fundOther) {
      return (
        <>
          <InputGroup className="mb-3 donate-other">
            <InputGroup.Text id="basic-addon1">
              {currencyPrefix}
            </InputGroup.Text>
            <Input
              invalid={!fund.isValid}
              type="number"
              value={fund.value}
              onChange={updateFund}
              min={minFund ? minFund / 100 : undefined}
              placeholder={t("Set an amount")}
              disabled={funding > 0}
            />
          </InputGroup>
          {fund.message && (
            <div className="form-error alert alert-danger" role="alert">
              {fund.message}
            </div>
          )}
          <Button
            variant="primary"
            type="button"
            onClick={startFund}
            disabled={funding > 0}
          >
            {t("Donate now")}
            {fund.value === funding && <Spinner animation="border" size="sm" />}
          </Button>
        </>
      );
    } else if (fundActive) {
      return (
        <div>
          <div className="row margin-sm campaign-donate">
            {DEFAULT_FUNDS.map((fund) => (
              <div className="col-lg-3" key={fund}>
                <Button
                  variant="primary"
                  type="button"
                  onClick={defaultFund(fund)}
                  disabled={funding > 0}
                >
                  {`${currencyPrefix} ${fund}`}
                  {fund === funding && <Spinner animation="border" size="sm" />}
                </Button>
              </div>
            ))}
            <div className="col-lg-3">
              <Button
                variant="secondary"
                type="button"
                onClick={showFundOther}
                disabled={funding > 0}
              >
                {t("Other")}
              </Button>
            </div>
          </div>
          {fund.message && (
            <div className="form-error alert alert-danger" role="alert">
              {fund.message}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <Button variant="primary" type="button" onClick={showFundActive}>
          {t("Donate now")}
        </Button>
      );
    }
  };

  // const likeClasses = classNames(
  //   "btn",
  //   { "btn-secondary": !didLike, "btn-primary": didLike },
  //   "campaign-like"
  // );

  const renderPhotoInfo = () => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Body>
          <Trans i18nKey="report_image_popover">
            Report unauthorized image use here:{" "}
            <a href={`mailto:fotos@club-meister.com?subject=${title}`}>
              fotos@club-meister.com
            </a>
          </Trans>
        </Popover.Body>
      </Popover>
    );
    return (
      <OverlayTrigger trigger="click" overlay={popover} rootClose>
        <p className="small d-inline-block" role="button">
          <i className="fas fa-info-circle info"></i>
          <span className="ms-1">{t("Photo usage")}</span>
        </p>
      </OverlayTrigger>
    );
  };

  const daysToGo = endAt && moment(endAt).diff(moment(), "days");

  return (
    <div className="campaign-content">
      <div className="container">
        <div className="campaign">
          <div className="campaign-item clearfix">
            <div className="campaign-image">
              {images && (
                <>
                  <Carousel controls={images?.length > 1}>
                    {images.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          src={image}
                          alt=""
                          key={index}
                          className="carousel-img"
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <div className="text-end mt-1">{renderPhotoInfo()}</div>
                </>
              )}
            </div>
            <div className="campaign-box">
              <Link to={`${club?.link}/explore`} className="category">
                {club?.title}
              </Link>
              <span className="date">
                {createdAt ? moment(createdAt).fromNow() : ""}
              </span>
              <h3>{title}</h3>
              <div className="campaign-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(description),
                  }}
                />
              </div>

              {videoUrl && (
                <div className="campaign-video mb-3">
                  <iframe
                    width="560"
                    height="315"
                    src={videoUrl.replace("/watch?v=", "/embed/")}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              )}

              <div className="process">
                <div className="process-info row">
                  <div className="process-pledged col">
                    <span className="no-wrap">
                      {currencyPrefix}{" "}
                      {numeral(raised / 100).format("0,0[.]00")}
                    </span>{" "}
                    {t("donated")}
                  </div>
                  <div className="process-funded col">
                    <span className="no-wrap">
                      {currencyPrefix} {numeral(goal / 100).format("0,0[.]00")}
                    </span>{" "}
                    {t("target amount")}
                  </div>
                  <div className="col">
                    <span className="no-wrap">
                      {numeral(fundings).format("0,0")}
                    </span>{" "}
                    {t("donations", { count: fundings })}
                  </div>
                  {/* <div className="col-lg-3">
                    <span className="no-wrap">
                      {numeral(likes).format("0,0")}
                    </span>{" "}
                    {t("like", { count: likes })}
                  </div> */}
                </div>
                <div className="raised">
                  <span style={{ width: `${progress * 100}%` }} />
                </div>
              </div>
              <Row>
                <Col>
                  <div className="user">
                    <strong>{t("This is an idea of")}:</strong>{" "}
                    {user?.displayName}
                  </div>
                </Col>
                <Col lg="auto">
                  {daysToGo && (
                    <div className="user">
                      {t("{{count}} days to go", { count: daysToGo })}
                    </div>
                  )}
                </Col>
              </Row>
              <div className="actions">
                {renderFunding()}
                <div className="actions-other d-sm-flex">
                  {/* <button className={likeClasses} onClick={like}>
                    {t("Like")}
                    <i className="far fa-thumbs-up fa-lg ms-1" />
                  </button> */}

                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => setShowShare(true)}
                  >
                    {t("Share")}
                    <i className="fas fa-share-alt fa-lg ms-1"></i>
                  </Button>
                </div>
                <Share
                  show={showShare}
                  onHide={() => setShowShare(false)}
                  campaign={campaign}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CampaignSummary);
