import classNames from "classnames";
import numeral from "numeral";
import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ChangeEvent,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { InputGroup } from "react-bootstrap";

interface Props
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "onChange"
  > {
  invalid?: boolean;
  prepend?: string | ReactNode;
  append?: string | ReactNode;
  value?: number;
  onChange?: (value?: number) => void;
  decimals?: number;
}
const Input = (props: Props) => {
  const {
    invalid,
    onChange,
    className,
    prepend,
    append,
    value,
    decimals,
    ...otherProps
  } = props;
  const format = `0,0${
    (decimals || 0) > 0 ? `[.][${Array(decimals).fill("0").join("")}]` : ""
  }`;
  const inputForValue = (value?: number) => {
    if (value === undefined || value === null) return "";
    if (format) return numeral(value).format(format);
    return `${value}`;
  };

  const [input, setInput] = useState(inputForValue(value));
  const inputClass = classNames(
    "form-control",
    {
      "is-invalid": invalid,
    },
    className,
  );

  useEffect(() => {
    setInput(inputForValue(value));
  }, [value]);

  const update = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const raw = numeral(input).value();

    let value: number | undefined = undefined;
    if (input !== "" && raw !== null) {
      const factor = Math.pow(10, decimals || 0);
      value = Math.floor(raw * factor) / factor;
    }

    if (onChange) {
      onChange(value);
    }

    if (input.endsWith("0") || input.endsWith(".") || input.endsWith(",")) {
      setInput(input);
    } else {
      setInput(inputForValue(value ?? 0)); // or handle `undefined` explicitly
    }
  };

  const renderPrepend = (prepend?: string | ReactNode) => {
    if (typeof prepend === "string") {
      return <InputGroup.Text>{prepend}</InputGroup.Text>;
    }
    return prepend;
  };
  const renderAppend = (append?: string | ReactNode) => {
    if (typeof append === "string") {
      return <InputGroup.Text>{append}</InputGroup.Text>;
    }
    return append;
  };

  return (
    <InputGroup>
      {renderPrepend(prepend)}
      <input
        className={inputClass}
        onChange={update}
        value={input}
        {...otherProps}
      />
      {renderAppend(append)}
    </InputGroup>
  );
};

export default Input;
