import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Table, Row, Col, Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Campaign, useStore } from "../../../store";

interface Props {
  campaigns: Campaign[];
}
const AdminCampaign = (props: Props) => {
  const { campaigns } = props;
  const { data } = useStore();
  const { t } = useTranslation();

  const renderCampaign = (campaign: Campaign) => {
    const path = campaign.link.slice(0, 4) + "admin/" + campaign.link.slice(4);
    const { club, title, user, createdAt } = campaign;
    return (
      <tr className="campaign" key={campaign.id}>
        <td>
          <Row>
            <Col sm="auto">
              <img className="logo" src={club?.logo} alt={t("Club")} />
            </Col>
            <Col>
              <div>
                {user?.id === "deleted" ? (
                  <cite className="campaign-user font-italic">
                    {t("User deleted")}
                  </cite>
                ) : (
                  <Link
                    to={`/${data.country?.id}/admin/user/${user?.id}`}
                    className="no-wrap"
                  >
                    <cite className="campaign-user">
                      {user?.displayName ?? t("Anonymous")}
                    </cite>
                  </Link>
                )}
                <span className="campaign-date">
                  {createdAt ? moment(createdAt).fromNow() : ""}
                </span>
              </div>
              {title}
            </Col>
          </Row>
        </td>
        <td className="col-fit">
          {campaign.isRejected ? (
            <Badge bg="danger">{t("Rejected")}</Badge>
          ) : campaign.isPublished ? (
            <Badge bg="success">{t("Published")}</Badge>
          ) : (
            <Badge bg="danger">{t("Not published")}</Badge>
          )}
        </td>
        <td className="col-fit">
          <Link className="btn btn-sm btn-primary me-2" to={path}>
            {t("Edit")}
          </Link>{" "}
          <Link className="btn btn-sm btn-primary me-2" to={`${path}/fund`}>
            {t("Funds")}
          </Link>
        </td>
      </tr>
    );
  };

  const renderEmpty = () => {
    return <div className="data-empty">{t("No campaigns found")}</div>;
  };

  return (
    <div className="container">
      {campaigns.length === 0 ? (
        renderEmpty()
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t("Campaign")}</th>
              <th>{t("Status")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>{campaigns.map(renderCampaign)}</tbody>
        </Table>
      )}
    </div>
  );
};

export default observer(AdminCampaign);
