import { useTranslation } from "react-i18next";

import { Info } from "../general/info";

export const ForClubs = () => {
  const { t } = useTranslation();

  const ITEMS = [
    {
      icon: <i className="fa fa-eye" aria-hidden="true" />,
      header: t("home.forclubs1.header"),
      text: t("home.forclubs1.text"),
    },
    {
      icon: <i className="fa fa-comments" aria-hidden="true" />,
      header: t("home.forclubs2.header"),
      text: t("home.forclubs2.text"),
    },
    {
      icon: <i className="fa fa-flag" aria-hidden="true" />,
      header: t("home.forclubs3.header"),
      text: t("home.forclubs3.text"),
    },
  ];

  return (
    <Info
      badge={
        <>
          <i className="fa-solid fa-futbol fa-2x me-2"></i>
          <strong>{t("home.forclubs.tag")}</strong>
        </>
      }
      header={t("home.forclubs.header")}
      content={ITEMS}
    />
  );
};
