import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useState } from "react";
import {
  Badge,
  Row,
  Col,
  Table,
  Modal,
  ListGroup,
  Button,
} from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { Comment, Report, useStore } from "../../store";
import Avatar from "../general/avatar";

interface Props {
  comments: Comment[];
  reports: Report[];
}
const AdminComment = (props: Props) => {
  const { comments, reports } = props;
  const { data } = useStore();
  const [commentReport, setCommentReport] = useState<Comment>();
  const { t } = useTranslation();

  const numberfReportsForComment = (comment: Comment) => {
    return reports.filter((report) => report.id === comment.id).length;
  };

  const hideReport = () => {
    setCommentReport(undefined);
  };

  const blockComment = async (comment?: Comment) => {
    try {
      if (!comment) throw new Error("Comment not found");
      await comment.update({ blocked: true });
    } catch (error) {
      console.error("Block comment failed: ", error);
    }
  };
  const unblockComment = async (comment?: Comment) => {
    try {
      if (!comment) throw new Error("Comment not found");
      await comment.update({ blocked: false });
    } catch (error) {
      console.error("Block comment failed: ", error);
    }
  };

  const renderReport = () => (
    <Modal show={!!commentReport} onHide={hideReport}>
      <Modal.Header closeButton>
        <Modal.Title>Comment reports</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {reports
            .filter((report) => report.id === commentReport?.id)
            .map((report) => (
              <ListGroup.Item>{report.text}</ListGroup.Item>
            ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" size="sm" onClick={hideReport}>
          <Trans>Cancel</Trans>
        </Button>
        {commentReport?.blocked ? (
          <Button
            variant="primary"
            size="sm"
            onClick={() => unblockComment(commentReport)}
          >
            <Trans>Unblock comment</Trans>
          </Button>
        ) : (
          <Button
            variant="primary"
            size="sm"
            onClick={() => blockComment(commentReport)}
          >
            <Trans>Block comment</Trans>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );

  const renderComment = (comment: Comment) => {
    const numberOfReports = numberfReportsForComment(comment);
    const { user, text, createdAt } = comment;

    return (
      <tr className="campaign" key={comment.id}>
        <td>
          <div className="comment-area">
            <Row>
              <Col sm="auto">
                <div className="comment-avatar">
                  <Avatar user={user} />
                </div>
              </Col>
              <Col>
                <div>
                  <Link
                    to={`/${data.country?.id}/admin/user/${user?.id}`}
                    className="no-wrap"
                  >
                    <cite className="comment-author">
                      {user?.displayName || t("Anonymous")}
                    </cite>
                  </Link>
                  <span className="comment-date">
                    {createdAt ? moment(createdAt).fromNow() : ""}
                  </span>
                </div>
                <Link to={comment?.campaign?.link || ""}>{text}</Link>
              </Col>
            </Row>
          </div>
        </td>
        <td>
          {numberOfReports > 0 && (
            <button
              className="no-border"
              onClick={() => setCommentReport(comment)}
            >
              <Badge bg="secondary">
                {t("{{count}} Reports", { count: numberOfReports })}
              </Badge>
            </button>
          )}
          {comment?.blocked && (
            <Badge bg="danger">
              <Trans>Blocked</Trans>
            </Badge>
          )}
        </td>
        <td>
          {comment?.blocked ? (
            <Button size="sm" onClick={() => unblockComment(comment)}>
              <Trans>Unblock</Trans>
            </Button>
          ) : (
            <Button size="sm" onClick={() => blockComment(comment)}>
              <Trans>Block</Trans>
            </Button>
          )}
        </td>
      </tr>
    );
  };

  const renderEmpty = () => {
    return <div className="data-empty">{t("No comments found")}</div>;
  };

  return (
    <div className="container">
      {comments.length === 0 ? (
        renderEmpty()
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t("Comment")}</th>
              <th>{t("Status")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>{comments.map(renderComment)}</tbody>
        </Table>
      )}
      {renderReport()}
    </div>
  );
};

export default observer(AdminComment);
