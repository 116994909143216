import React from "react";

import { Profile } from "../../store";

interface Props {
  user?: Profile;
  size?: number;
  showName?: boolean;
}
const Avatar = ({ user, size = 40, showName = false }: Props) => {
  const style = size
    ? {
        width: size,
        height: size,
        borderRadius: size / 2,
      }
    : undefined;

  return (
    <>
      {user?.photoURL ? (
        <div className="avatar d-inline-block" style={style}>
          <img src={user?.photoURL} alt="" />
        </div>
      ) : (
        <div className="avatar btn bg-primary text-white" style={style}>
          {user?.displayName?.charAt(0)}
        </div>
      )}
      {showName && (
        <span className="d-none ms-2 d-md-inline">{user?.displayName}</span>
      )}
    </>
  );
};

export default Avatar;
